// Auto generated file
// Do not change '//Generator' comments
import { RouteItem } from 'onka-react-admin-core';
import { route as liveDataTimesheetSearchRoute } from './pages/liveDataTimesheetSearch';
import { route as liveDataUserSearchRoute } from './pages/liveDataUserSearch';
import { route as liveDataJobSearchRoute } from './pages/liveDataJobSearch';
import { route as liveDataAwlSearchRoute } from './pages/liveDataAwlSearch';
import { route as liveDataUserJobSearchRoute } from './pages/liveDataUserJobSearch';
import { route as reconciled_timesheets_year_2021SearchRoute } from './pages/reconciled_timesheets_year_2021Search';
import { route as clarizenJobsToCreateSearchRoute } from './pages/clarizenJobsToCreateSearch';
import { route as altairUserSearchRoute } from './pages/altairUserSearch';
import { route as altairAgencyRuleSearchRoute } from './pages/altairAgencyRuleSearch';
import { route as altairAlternateCalendarSearchRoute } from './pages/altairAlternateCalendarSearch';
import { route as altairApproverSearchRoute } from './pages/altairApproverSearch';
import { route as altairBillingRateSearchRoute } from './pages/altairBillingRateSearch';
import { route as altairBusinessUnitSearchRoute } from './pages/altairBusinessUnitSearch';
import { route as altairClientSearchRoute } from './pages/altairClientSearch';
import { route as altairCostRateSearchRoute } from './pages/altairCostRateSearch';
import { route as altairCountryCalendarSearchRoute } from './pages/altairCountryCalendarSearch';
import { route as altairCrossCompanyRuleSearchRoute } from './pages/altairCrossCompanyRuleSearch';
import { route as altairCrossCompanyRuleFilterSearchRoute } from './pages/altairCrossCompanyRuleFilterSearch';
import { route as altairEmailReminderSettingSearchRoute } from './pages/altairEmailReminderSettingSearch';
import { route as altairIntraCompanyRuleSearchRoute } from './pages/altairIntraCompanyRuleSearch';
import { route as altairJobSearchRoute } from './pages/altairJobSearch';
import { route as altairJobGroupSearchRoute } from './pages/altairJobGroupSearch';
import { route as altairJobTitleSearchRoute } from './pages/altairJobTitleSearch';
import { route as altairLocationSearchRoute } from './pages/altairLocationSearch';
import { route as altairOnBehalfOfMappingSearchRoute } from './pages/altairOnBehalfOfMappingSearch';
import { route as altairOrgStructureSearchRoute } from './pages/altairOrgStructureSearch';
import { route as altairPilotAgencySearchRoute } from './pages/altairPilotAgencySearch';
import { route as altairRoleSearchRoute } from './pages/altairRoleSearch';
import { route as altairTaskSearchRoute } from './pages/altairTaskSearch';
import { route as altairTimezoneSearchRoute } from './pages/altairTimezoneSearch';
import { route as altairUserContractSearchRoute } from './pages/altairUserContractSearch';
import { route as altairUserContractFieldSearchRoute } from './pages/altairUserContractFieldSearch';
import { route as altairUserGroups_SingleOrgSearchRoute } from './pages/altairUserGroups_SingleOrgSearch';
import { route as altairUserOrgSearchRoute } from './pages/altairUserOrgSearch';
import { route as altairUsersMappingSearchRoute } from './pages/altairUsersMappingSearch';
import { route as altairWorkCodeSearchRoute } from './pages/altairWorkCodeSearch';
import { route as altairXCompanyDownloadSearchRoute } from './pages/altairXCompanyDownloadSearch';
import { route as reconcileSummarySearchRoute } from './pages/reconcileSummarySearch';
import { route as migrationSchedulerAgencySearchRoute } from './pages/migrationSchedulerAgencySearch';
import { route as roarSettingSearchRoute } from './pages/roarSettingSearch';
import { route as altairRolesSingleOrgSearchRoute } from './pages/altairRolesSingleOrgSearch';
import { route as salesForceJobSearchRoute } from './pages/salesForceJobSearch';
import { route as reconcileMultiOrgDataSearchRoute } from './pages/reconcileMultiOrgDataSearch';
import { route as reconcileSingleOrgDataSearchRoute } from './pages/reconcileSingleOrgDataSearch';
import { route as outboundCallSearchRoute } from './pages/outboundCallSearch';
import { route as reconcilev2DataSearchRoute } from './pages/reconcilev2DataSearch';
import { route as reconcilev2SummarySearchRoute } from './pages/reconcilev2SummarySearch';
import { route as altairCostRateSingleOrgSearchRoute } from './pages/altairCostRateSingleOrgSearch';
//Generator1

const routes: RouteItem[] = [
liveDataTimesheetSearchRoute,
liveDataUserSearchRoute,
liveDataJobSearchRoute,
liveDataAwlSearchRoute,
liveDataUserJobSearchRoute,
reconciled_timesheets_year_2021SearchRoute,
clarizenJobsToCreateSearchRoute,
altairUserSearchRoute,
altairAgencyRuleSearchRoute,
altairAlternateCalendarSearchRoute,
altairApproverSearchRoute,
altairBillingRateSearchRoute,
altairBusinessUnitSearchRoute,
altairClientSearchRoute,
altairCostRateSearchRoute,
altairCountryCalendarSearchRoute,
altairCrossCompanyRuleSearchRoute,
altairCrossCompanyRuleFilterSearchRoute,
altairEmailReminderSettingSearchRoute,
altairIntraCompanyRuleSearchRoute,
altairJobSearchRoute,
altairJobGroupSearchRoute,
altairJobTitleSearchRoute,
altairLocationSearchRoute,
altairOnBehalfOfMappingSearchRoute,
altairOrgStructureSearchRoute,
altairPilotAgencySearchRoute,
altairRoleSearchRoute,
altairTaskSearchRoute,
altairTimezoneSearchRoute,
altairUserContractSearchRoute,
altairUserContractFieldSearchRoute,
altairUserGroups_SingleOrgSearchRoute,
altairUserOrgSearchRoute,
altairUsersMappingSearchRoute,
altairWorkCodeSearchRoute,
altairXCompanyDownloadSearchRoute,
reconcileSummarySearchRoute,
migrationSchedulerAgencySearchRoute,
roarSettingSearchRoute,
altairRolesSingleOrgSearchRoute,
salesForceJobSearchRoute,
reconcileMultiOrgDataSearchRoute,
reconcileSingleOrgDataSearchRoute,
outboundCallSearchRoute,
reconcilev2DataSearchRoute,
reconcilev2SummarySearchRoute,
altairCostRateSingleOrgSearchRoute,
//Generator2
];

export default routes;