// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/ReconcileSummarySearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'ReconcileSummary',
  menu: 'migration',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'filterData',
      validators: [Validators.required, Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdAt',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedAt',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'totalMo',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'totalSo',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'missingMo',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'missingSo',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'discrepancies',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'status',
      enumName: 'ReconcileSummaryStatus',
      enum: StoreOldApiEnums.ReconcileSummaryStatus,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'discrepancyCount',
      validators: [Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'missingMoCount',
      validators: [Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'missingSoCount',
      validators: [Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCode',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'createdAt2Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdAt3LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'status',
      enumName: 'ReconcileSummaryStatus',
      enum: StoreOldApiEnums.ReconcileSummaryStatus,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'agencyCode'
    }),
    new PageField({
      name: 'discrepancyCount',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'missingMoCount',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'missingSoCount',
      filterComponent: CC.NumberComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'agencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'filterData',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedAt',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'status',
      enumName: 'ReconcileSummaryStatus',
      enum: StoreOldApiEnums.ReconcileSummaryStatus,
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'totalMo',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'totalSo',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'discrepancyCount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'missingMoCount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'missingSoCount',
      dataType: 'number'
    })
  ],
});

