// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairAgencyRuleSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairAgencyRule',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyIdentification',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'name1',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCompanyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'dWIndicator',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'taskUsageInAgency',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'rigidAgencyOrNot',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'percentageTimesheetUI',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lowestQuantityfraction',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'nbofHoursperday',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'linkedAgencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isAlternateCalendarAvailable',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'agencyIdentification'
    }),
    new PageField({
      name: 'name1'
    }),
    new PageField({
      name: 'hFMCode'
    }),
    new PageField({
      name: 'agencyCompanyCode'
    }),
    new PageField({
      name: 'dWIndicator'
    }),
    new PageField({
      name: 'taskUsageInAgency',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'rigidAgencyOrNot',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'percentageTimesheetUI',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'lowestQuantityfraction'
    }),
    new PageField({
      name: 'nbofHoursperday'
    }),
    new PageField({
      name: 'linkedAgencyCode'
    }),
    new PageField({
      name: 'isAlternateCalendarAvailable',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'agencyIdentification',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'name1',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'hFMCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyCompanyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'dWIndicator',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'taskUsageInAgency',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'rigidAgencyOrNot',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'percentageTimesheetUI',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lowestQuantityfraction',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'nbofHoursperday',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'linkedAgencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isAlternateCalendarAvailable',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    })
  ],
});

