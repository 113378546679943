// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairXCompanyDownloadSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairXCompanyDownload',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'permissionType',
      enumName: 'XCompanyRuleTypes',
      enum: StoreOldApiEnums.XCompanyRuleTypes,
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'borrowingAgency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'borrowingJob',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'timesheetLabourCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'billableIndicator',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lendingAgency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'permittedLabourCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'permittedEmployee',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lendingJobId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'accountingSchema',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lumpSum',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'cappedHours',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'effectiveFrom',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'effectiveTo',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isEnabled',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastUpdated',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastUpdatedBy',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'permissionType',
      enumName: 'XCompanyRuleTypes',
      enum: StoreOldApiEnums.XCompanyRuleTypes,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'borrowingAgency'
    }),
    new PageField({
      name: 'borrowingJob'
    }),
    new PageField({
      name: 'timesheetLabourCode'
    }),
    new PageField({
      name: 'billableIndicator'
    }),
    new PageField({
      name: 'lendingAgency'
    }),
    new PageField({
      name: 'permittedLabourCode'
    }),
    new PageField({
      name: 'permittedEmployee'
    }),
    new PageField({
      name: 'lendingJobId'
    }),
    new PageField({
      name: 'accountingSchema'
    }),
    new PageField({
      name: 'lumpSum'
    }),
    new PageField({
      name: 'cappedHours',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'effectiveFrom13Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveFrom14LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveTo15Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveTo16LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'isEnabled',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'lastUpdated18Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastUpdated19LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastUpdatedBy'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'permissionType',
      enumName: 'XCompanyRuleTypes',
      enum: StoreOldApiEnums.XCompanyRuleTypes,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'borrowingAgency',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'borrowingJob',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'timesheetLabourCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'billableIndicator',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lendingAgency',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'permittedLabourCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'permittedEmployee',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lendingJobId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'accountingSchema',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lumpSum',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'cappedHours',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'effectiveFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'effectiveTo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isEnabled',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lastUpdated',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lastUpdatedBy',
      dataType: 'string'
    })
  ],
});

