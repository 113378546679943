// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairTimezoneSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairTimezone',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'city',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'city_ascii',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lat',
      validators: [Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lng',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'pop',
      validators: [Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'country',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'iso2',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'iso3',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'province',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'timezone',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'state_ansi',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'exactCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'exactProvince',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'city'
    }),
    new PageField({
      name: 'city_ascii'
    }),
    new PageField({
      name: 'lat',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'lng',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'pop',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'country'
    }),
    new PageField({
      name: 'iso2'
    }),
    new PageField({
      name: 'iso3'
    }),
    new PageField({
      name: 'province'
    }),
    new PageField({
      name: 'timezone'
    }),
    new PageField({
      name: 'state_ansi'
    }),
    new PageField({
      name: 'exactCity'
    }),
    new PageField({
      name: 'exactProvince'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'city',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'city_ascii',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lat',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'lng',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'pop',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'country',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'iso2',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'iso3',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'province',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'timezone',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'state_ansi',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'exactCity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'exactProvince',
      dataType: 'string'
    })
  ],
});

