// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairUserOrgSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairUserOrg',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMEntityCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMEntityName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyName',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'costCenterId'
    }),
    new PageField({
      name: 'costCenterName'
    }),
    new PageField({
      name: 'profitCenterId'
    }),
    new PageField({
      name: 'profitCenterName'
    }),
    new PageField({
      name: 'hFMEntityCode'
    }),
    new PageField({
      name: 'hFMEntityName'
    }),
    new PageField({
      name: 'companyCode'
    }),
    new PageField({
      name: 'agencyId'
    }),
    new PageField({
      name: 'agencyName'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'costCenterId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'hFMEntityCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'hFMEntityName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyName',
      dataType: 'string'
    })
  ],
});

