// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/Reconciled_timesheets_year_2021Search',
  moduleKey: 'StoreOldApi',
  pageKey: 'Reconciled_timesheets_year_2021',
  menu: 'reconcile',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: true,
  delete: true,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'timesheetId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'jobHFMCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'altairCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'jobID',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'reportedDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isDurationIncorrect',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isC_DurationIncorrect',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isDuration_CDurationIncorrect',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isStateIncorrect',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isMissingInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'state_Clarizen',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'state_MW',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'c_SAPAckStatus',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'duration_MW',
      validators: [Validators.max(3.402823E+38), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'duration_CLZ',
      validators: [Validators.max(3.402823E+38), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'c_Duration_CLZ',
      validators: [Validators.max(3.402823E+38), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'reasonForFailureToFix',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isDeletedInMW',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'id'
    }),
    new PageField({
      name: 'timesheetId'
    }),
    new PageField({
      name: 'agencyCode'
    }),
    new PageField({
      name: 'jobHFMCode'
    }),
    new PageField({
      name: 'altairCode'
    }),
    new PageField({
      name: 'jobID'
    }),
    new PageField({
      name: 'reportedDate6Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'reportedDate7LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'state_Clarizen'
    }),
    new PageField({
      name: 'state_MW'
    }),
    new PageField({
      name: 'reasonForFailureToFix'
    }),
    new PageField({
      name: 'isDeletedInMW',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'timesheetId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'jobHFMCode',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'altairCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'jobID',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'reportedDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isDurationIncorrect',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isC_DurationIncorrect',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isDuration_CDurationIncorrect',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isStateIncorrect',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isMissingInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'state_Clarizen',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'state_MW',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'c_SAPAckStatus',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'duration_MW',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'duration_CLZ',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'c_Duration_CLZ',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'reasonForFailureToFix',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isDeletedInMW',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    })
  ],
});

