// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/Reconcilev2SummarySearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'Reconcilev2Summary',
  menu: 'migration',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: true,
  delete: true,
  export: true,
  inlineEditing: true,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'moFromDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'soFromDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'moIntervalMinutes',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'soIntervalMinutes',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastRunDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'moStopDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'soStopDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'moToDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'soToDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'moRunCount',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'soRunCount',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'scheduler',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'moFromDate2Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'moFromDate3LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'soFromDate4Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'soFromDate5LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'moIntervalMinutes',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'soIntervalMinutes',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'lastRunDate8Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastRunDate9LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'moStopDate10Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'moStopDate11LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'soStopDate12Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'soStopDate13LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'moToDate14Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'moToDate15LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'soToDate16Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'soToDate17LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'agencyCode'
    }),
    new PageField({
      name: 'moRunCount',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'soRunCount',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'scheduler',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'moFromDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'soFromDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'moIntervalMinutes',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'soIntervalMinutes',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'lastRunDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'moStopDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'soStopDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'moToDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'soToDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyCode',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'moRunCount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'soRunCount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'scheduler',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    })
  ],
});

