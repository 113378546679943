import React from 'react';
import packageJson from '../../../package.json';

const api = process.env.REACT_APP_API_URL;

function About() {
  return (
    <div>
      <div>API URL: {api}</div>
      <div>Version: {packageJson.version}</div>
    </div>
  );
}

export default About;
