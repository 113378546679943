// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairRoleSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairRole',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'userHFMEntity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'altairCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lionLoginUser',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'businessRole',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'businessRoleHFMEntity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'validFrom',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'validTo',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'insertionDeletionIndicator',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastChangeDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastChangeTime',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'allowUpdate',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isClarizenProfileCreated',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isCreatedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isUpdatedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isLockedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isActiveInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isDeleted',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedOn',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdOn',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedBy',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdBy',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'userHFMEntity'
    }),
    new PageField({
      name: 'altairCode'
    }),
    new PageField({
      name: 'lionLoginUser'
    }),
    new PageField({
      name: 'businessRole'
    }),
    new PageField({
      name: 'businessRoleHFMEntity'
    }),
    new PageField({
      name: 'agencyCode'
    }),
    new PageField({
      name: 'validFrom7Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'validFrom8LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'validTo9Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'validTo10LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'insertionDeletionIndicator'
    }),
    new PageField({
      name: 'lastChangeDate12Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastChangeDate13LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastChangeTime14Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastChangeTime15LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'allowUpdate',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isClarizenProfileCreated',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isCreatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isUpdatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isLockedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isActiveInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isDeleted',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'updatedOn23Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedOn24LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdOn25Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdOn26LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedBy'
    }),
    new PageField({
      name: 'createdBy'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'userHFMEntity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'altairCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lionLoginUser',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'businessRole',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'businessRoleHFMEntity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'validFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'validTo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'insertionDeletionIndicator',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lastChangeDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lastChangeTime',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'allowUpdate',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isClarizenProfileCreated',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isCreatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isUpdatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isLockedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isActiveInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isDeleted',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedOn',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdOn',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedBy',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdBy',
      dataType: 'string'
    })
  ],
});

