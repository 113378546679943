import React from 'react';

function Dashboard() {
  return (
    <div>
      v0.0.1
    </div>
  );
}

export default Dashboard;
