// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import adminApiEnums from '../../adminApiEnums';

export const pageConfig = new PageConfig({
  route: '/AdminApi/AdminUserSearch',
  moduleKey: 'AdminApi',
  pageKey: 'AdminUser',
  menu: 'AdminApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: true,
  delete: true,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'name',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'email',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'altairCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'passwordHash',
      validators: [Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'allowIpAddress',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'status',
      enumName: 'Status',
      enum: adminApiEnums.Status,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lockoutEndDateUtc',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'accessFailedCount',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'businessRole',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isSuper',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'scope',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'name'
    }),
    new PageField({
      name: 'email'
    }),
    new PageField({
      name: 'status',
      enumName: 'Status',
      enum: adminApiEnums.Status,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'lockoutEndDateUtc4Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lockoutEndDateUtc5LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'scope',
      filterComponent: CC.TextareaComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'altairCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'name',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'email',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'status',
      enumName: 'Status',
      enum: adminApiEnums.Status,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'lockoutEndDateUtc',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'accessFailedCount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'businessRole',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'scope',
      dataType: 'string'
    })
  ],
});

