// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairUserGroups_SingleOrgSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairUserGroups_SingleOrg',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'businessUnit',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'externalID',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'parentExternalID',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'level',
      validators: [Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'orgStructureType',
      enumName: 'OrgStructureTypes',
      enum: StoreOldApiEnums.OrgStructureTypes,
      validators: [Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMEntityCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMEntityDescription',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyDescription',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyDescription',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCountry',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCurrency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterDescription',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterCountry',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterCurrency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterEffectiveFrom',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterEffectiveTo',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterLock',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterDescription',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterCountry',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterCurrency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterLock',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterEffectiveFrom',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterEffectiveTo',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'salesOrgCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'salesOrgDescription',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'resourcingGroup',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isDeleted',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isCreatedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isUpdatedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isLockedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isActiveInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedOn',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdOn',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedBy',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdBy',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'businessRole',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'clarizenProfileId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'businessUnitName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'clarizenProfileName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'userGroupName',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'businessUnit'
    }),
    new PageField({
      name: 'externalID'
    }),
    new PageField({
      name: 'parentExternalID'
    }),
    new PageField({
      name: 'level',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'orgStructureType',
      enumName: 'OrgStructureTypes',
      enum: StoreOldApiEnums.OrgStructureTypes,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'hFMEntityCode'
    }),
    new PageField({
      name: 'hFMEntityDescription'
    }),
    new PageField({
      name: 'agencyCode'
    }),
    new PageField({
      name: 'agencyDescription'
    }),
    new PageField({
      name: 'companyCode'
    }),
    new PageField({
      name: 'companyDescription'
    }),
    new PageField({
      name: 'companyCountry'
    }),
    new PageField({
      name: 'companyCity'
    }),
    new PageField({
      name: 'companyCurrency'
    }),
    new PageField({
      name: 'costCenterCode'
    }),
    new PageField({
      name: 'costCenterDescription'
    }),
    new PageField({
      name: 'costCenterCountry'
    }),
    new PageField({
      name: 'costCenterCity'
    }),
    new PageField({
      name: 'costCenterCurrency'
    }),
    new PageField({
      name: 'costCenterEffectiveFrom20Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterEffectiveFrom21LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterEffectiveTo22Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterEffectiveTo23LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterLock',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'profitCenterCode'
    }),
    new PageField({
      name: 'profitCenterDescription'
    }),
    new PageField({
      name: 'profitCenterCountry'
    }),
    new PageField({
      name: 'profitCenterCity'
    }),
    new PageField({
      name: 'profitCenterCurrency'
    }),
    new PageField({
      name: 'profitCenterLock',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'profitCenterEffectiveFrom31Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'profitCenterEffectiveFrom32LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'profitCenterEffectiveTo33Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'profitCenterEffectiveTo34LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'salesOrgCode'
    }),
    new PageField({
      name: 'salesOrgDescription'
    }),
    new PageField({
      name: 'resourcingGroup',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isDeleted',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isCreatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isUpdatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isLockedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isActiveInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'updatedOn43Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedOn44LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdOn45Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdOn46LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedBy'
    }),
    new PageField({
      name: 'createdBy'
    }),
    new PageField({
      name: 'businessRole'
    }),
    new PageField({
      name: 'clarizenProfileId'
    }),
    new PageField({
      name: 'businessUnitName'
    }),
    new PageField({
      name: 'clarizenProfileName'
    }),
    new PageField({
      name: 'userGroupName'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'businessUnit',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'externalID',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'parentExternalID',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'level',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'orgStructureType',
      enumName: 'OrgStructureTypes',
      enum: StoreOldApiEnums.OrgStructureTypes,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'hFMEntityCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'hFMEntityDescription',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyDescription',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyDescription',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCountry',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCurrency',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterDescription',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterCountry',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterCity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterCurrency',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterEffectiveFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterEffectiveTo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterLock',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterDescription',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterCountry',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterCity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterCurrency',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterLock',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterEffectiveFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterEffectiveTo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'salesOrgCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'salesOrgDescription',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'resourcingGroup',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isDeleted',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isCreatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isUpdatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isLockedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isActiveInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedOn',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdOn',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedBy',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdBy',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'businessRole',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'clarizenProfileId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'businessUnitName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'clarizenProfileName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'userGroupName',
      dataType: 'string'
    })
  ],
});

