import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  allInputs,
  ApiBusinessLogic,
  DetailComponentProp,
  GridComponentProp,
  InputComponentProp,
  LibService,
  UIManager,
  makeStyles,
} from 'onka-react-admin-core';
import { Button, Grid } from '@mui/material';

function PrettyJsonComponent(props: DetailComponentProp) {
  var val = <div>{props.rowData}</div>;
  try {
    val = <pre>{JSON.stringify(JSON.parse(props.rowData), null, 2) }</pre>;
  } catch (error) {
    // ignored
  }
  return (
    <div>
      <allInputs.DetailFieldComponent {...props} rowData={val} />
    </div>
  );
}

var CustomComponents = {
  ...allInputs,
  PrettyJsonComponent,
};

// override components
//CustomComponents.InputComponent = SomeComponent;

export default CustomComponents;
