// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairOrgStructureSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairOrgStructure',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterCountry',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterEndDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterEndDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterCountry',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMEntityCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMEntityName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'salesOrgId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'salesOrgName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterCurrency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'companyCodeCountry',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterDateFrom',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'profitCenterLock',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterDateFrom',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'costCenterLock',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'costCenterCountry'
    }),
    new PageField({
      name: 'costCenterId'
    }),
    new PageField({
      name: 'costCenterName'
    }),
    new PageField({
      name: 'costCenterEndDate4Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterEndDate5LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterCity'
    }),
    new PageField({
      name: 'profitCenterId'
    }),
    new PageField({
      name: 'profitCenterName'
    }),
    new PageField({
      name: 'profitCenterEndDate9Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'profitCenterEndDate10LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'profitCenterCountry'
    }),
    new PageField({
      name: 'profitCenterCity'
    }),
    new PageField({
      name: 'agencyId'
    }),
    new PageField({
      name: 'agencyName'
    }),
    new PageField({
      name: 'hFMEntityCode'
    }),
    new PageField({
      name: 'hFMEntityName'
    }),
    new PageField({
      name: 'companyCode'
    }),
    new PageField({
      name: 'companyName'
    }),
    new PageField({
      name: 'companyCity'
    }),
    new PageField({
      name: 'salesOrgId'
    }),
    new PageField({
      name: 'salesOrgName'
    }),
    new PageField({
      name: 'costCenterCurrency'
    }),
    new PageField({
      name: 'companyCodeCountry'
    }),
    new PageField({
      name: 'profitCenterDateFrom24Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'profitCenterDateFrom25LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'profitCenterLock'
    }),
    new PageField({
      name: 'costCenterDateFrom27Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterDateFrom28LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'costCenterLock'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'costCenterCountry',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterEndDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterCity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterEndDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterCountry',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterCity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'hFMEntityCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'hFMEntityName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCity',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'salesOrgId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'salesOrgName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterCurrency',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'companyCodeCountry',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterDateFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'profitCenterLock',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterDateFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'costCenterLock',
      dataType: 'string'
    })
  ],
});

