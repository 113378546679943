// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairCrossCompanyRuleSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairCrossCompanyRule',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'ruleType',
      enumName: 'XCompanyRuleTypes',
      enum: StoreOldApiEnums.XCompanyRuleTypes,
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'borrowingAgencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lendingAgencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'borrowingJobId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'borrowingTaskId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'borrowingJobName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lendingJobId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lendingJobName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'timesheetLabourWorkCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'defaultBillableIndicator',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'accountingSchema',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'totalHours',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lumpSum',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'effectiveFrom',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'effectiveTo',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'enabled',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'deleted',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdBy',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastUpdatedBy',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdByName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastUpdatedByName',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'ruleType',
      enumName: 'XCompanyRuleTypes',
      enum: StoreOldApiEnums.XCompanyRuleTypes,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'borrowingAgencyCode'
    }),
    new PageField({
      name: 'lendingAgencyCode'
    }),
    new PageField({
      name: 'borrowingJobId'
    }),
    new PageField({
      name: 'borrowingTaskId'
    }),
    new PageField({
      name: 'borrowingJobName'
    }),
    new PageField({
      name: 'lendingJobId'
    }),
    new PageField({
      name: 'lendingJobName'
    }),
    new PageField({
      name: 'timesheetLabourWorkCode'
    }),
    new PageField({
      name: 'defaultBillableIndicator'
    }),
    new PageField({
      name: 'accountingSchema'
    }),
    new PageField({
      name: 'totalHours',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'lumpSum'
    }),
    new PageField({
      name: 'effectiveFrom14Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveFrom15LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveTo16Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveTo17LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'enabled',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'deleted',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'createdDate20Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdDate21LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedDate22Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedDate23LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdBy'
    }),
    new PageField({
      name: 'lastUpdatedBy'
    }),
    new PageField({
      name: 'createdByName'
    }),
    new PageField({
      name: 'lastUpdatedByName'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'ruleType',
      enumName: 'XCompanyRuleTypes',
      enum: StoreOldApiEnums.XCompanyRuleTypes,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'borrowingAgencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lendingAgencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'borrowingJobId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'borrowingTaskId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'borrowingJobName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lendingJobId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lendingJobName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'timesheetLabourWorkCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'defaultBillableIndicator',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'accountingSchema',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'totalHours',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'lumpSum',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'effectiveFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'effectiveTo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'enabled',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'deleted',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdBy',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lastUpdatedBy',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdByName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'lastUpdatedByName',
      dataType: 'string'
    })
  ],
});

