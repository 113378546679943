import React from 'react';
import { Route } from 'react-router-dom';
import { ConfigService, Admin, Menu, StaticService, AccountBusinessLogic, defaultRequestOptions } from 'onka-react-admin-core';
import AdminIcon from '@mui/icons-material/Person';
import TimesheetIcon from '@mui/icons-material/TimelineOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import AltairIcon from '@mui/icons-material/ViewModule';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import UserIntegrationIcon from '@mui/icons-material/Person';
import About from './ui/public/About';
import config from './data/config';
import panelRoutes from './ui/panel/modules';
import Dashboard from './ui/panel/pages/Dashboard';
import { Footer } from './ui/panel/pages/Footer';
import { PublicFooter } from './ui/panel/pages/PublicFooter';
import './ext/scripts';
import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/system';

export const menus: Menu[] = [
  new Menu({
    menuKey: 'AdminApi',
    routes: [],
    hasAccess: false,
    icon: <AdminIcon />,
  }),
  new Menu({
    menuKey: 'StoreOldApi',
    routes: [],
    hasAccess: false,
    icon: <TimesheetIcon />,
  }),
  new Menu({
    menuKey: 'cosmos',
    routes: [],
    hasAccess: false,
    icon: <TimesheetIcon />,
  }),
  new Menu({
    menuKey: 'migration',
    routes: [],
    hasAccess: false,
    icon: <TimesheetIcon />,
  }),
  new Menu({
    menuKey: 'StoreApi',
    routes: [],
    hasAccess: false,
    icon: <TimesheetIcon />,
  }),
  new Menu({
    menuKey: 'Email',
    routes: [],
    hasAccess: false,
    icon: <EmailIcon />,
  }),
  new Menu({
    menuKey: 'UserIntegration',
    routes: [],
    hasAccess: false,
    icon: <UserIntegrationIcon />,
  }),
  new Menu({
    menuKey: 'settings',
    routes: [],
    hasAccess: false,
    icon: <AltairIcon />,
  }),
];

const theme : Partial<Theme> | ((outerTheme: Theme) => Theme) | undefined = createTheme({
  palette: {
    primary: { main: '#77215e' },
  },
  components : {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
});

function App() {
  console.log('App render');
  function onload(): Promise<any> {
    defaultRequestOptions.withCredentials = false;
    defaultRequestOptions.timeout = 600000;
    StaticService.TOKEN_NAME = 'RoarToken';
    AccountBusinessLogic.instance().loginUrl = "AdminApi/public/login";
    // AccountBusinessLogic.instance().logoutUrl = '/AdminApi/AdminUserUpdate/logout';
    ConfigService.instance().setApiUrl(config.API_URL || '');
    ConfigService.instance().setIsProd(config.IS_PROD);
    ConfigService.instance().setLangList({
      en: () => require('./ui/panel/modules/l10n/en.json'),
      tr: () => require('./ui/panel/modules/l10n/fr.json'),
    });
    return Promise.resolve();
  }
  const logo = 'https://timesheet.roar.pub/images/email-logo.png';

  return (
    <Admin
      onLoad={onload}
      rootRoutes={[<Route key="0" path="/about" element={<About />}></Route>]}
      dashboard={<Dashboard />}
      footer={<Footer />}
      toolbar={{}}
      menu={{
        menus,
        routes: panelRoutes,
        logo,
      }}
      login={{
        footer: <PublicFooter />,
        logo,
      }}
      theme={theme}
    ></Admin>
  );
}

export default App;
