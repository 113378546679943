// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/Reconcilev2DataSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'Reconcilev2Data',
  menu: 'migration',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: true,
  delete: true,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'entityId',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCode',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastUpdatedDateMo',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'recDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'recStatus',
      enumName: 'Reconcilev2Status',
      enum: StoreOldApiEnums.Reconcilev2Status,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hashMo',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hashSo',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stateMo',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stateSo',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdAt',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedAt',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastUpdatedDateSo',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'discrepancyCount',
      validators: [Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'discrepancyNames',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'dataMo',
      validators: [Validators.maxLength(8000)],
      fieldSize: 6,
      detailComponent: CC.PrettyJsonComponent,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'dataSo',
      validators: [Validators.maxLength(8000)],
      fieldSize: 6,
      detailComponent: CC.PrettyJsonComponent,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'discrepancies',
      validators: [Validators.maxLength(8000)],
      fieldSize: 6,
      detailComponent: CC.PrettyJsonComponent,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'entityId'
    }),
    new PageField({
      name: 'agencyCode'
    }),
    new PageField({
      name: 'lastUpdatedDateMo4Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastUpdatedDateMo5LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'recDate6Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'recDate7LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'recStatus',
      enumName: 'Reconcilev2Status',
      enum: StoreOldApiEnums.Reconcilev2Status,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'dataMo',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'dataSo',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'hashMo',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'hashSo',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'stateMo'
    }),
    new PageField({
      name: 'stateSo'
    }),
    new PageField({
      name: 'createdAt15Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdAt16LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedAt17Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedAt18LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastUpdatedDateSo19Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'lastUpdatedDateSo20LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'discrepancies',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'discrepancyCount',
      filterComponent: CC.NumberComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'entityType',
      enumName: 'EntityType',
      enum: StoreOldApiEnums.EntityType,
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'agencyCode',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'entityId',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'stateMo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'stateSo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'recDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'recStatus',
      enumName: 'Reconcilev2Status',
      enum: StoreOldApiEnums.Reconcilev2Status,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'createdAt',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedAt',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'discrepancyCount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'discrepancyNames',
      dataType: 'string'
    })
  ],
});

