// Auto generated file
// Do not change '//Generator' comments
import { RouteItem } from 'onka-react-admin-core';
import { route as inboundSearchRoute } from './pages/inboundSearch';
import { route as inboundAltairRoleStackSearchRoute } from './pages/inboundAltairRoleStackSearch';
import { route as altairRoleDefinitionSearchRoute } from './pages/altairRoleDefinitionSearch';
import { route as inboundAltairRoleSearchRoute } from './pages/inboundAltairRoleSearch';
import { route as userAltairRoleSearchRoute } from './pages/userAltairRoleSearch';
import { route as userFinancialDetailSearchRoute } from './pages/userFinancialDetailSearch';
import { route as userBusinessUnitsAccessSearchRoute } from './pages/userBusinessUnitsAccessSearch';
import { route as outboundSearchRoute } from './pages/outboundSearch';
import { route as altairOrgStructureSearchRoute } from './pages/altairOrgStructureSearch';
import { route as altairAgencyRuleSearchRoute } from './pages/altairAgencyRuleSearch';
import { route as altairBusinessUnitSearchRoute } from './pages/altairBusinessUnitSearch';
import { route as altairUserSearchRoute } from './pages/altairUserSearch';
import { route as emailHistorySearchRoute } from './pages/emailHistorySearch';
import { route as emailSettingSearchRoute } from './pages/emailSettingSearch';
import { route as emailTemplateSearchRoute } from './pages/emailTemplateSearch';
import { route as wipCorrectionSearchRoute } from './pages/wipCorrectionSearch';
import { route as altairJobv2SearchRoute } from './pages/altairJobv2Search';
import { route as pXPProjectCreationSearchRoute } from './pages/pXPProjectCreationSearch';
import { route as inboundUserStackSearchRoute } from './pages/inboundUserStackSearch';
import { route as roarUserSearchRoute } from './pages/roarUserSearch';
import { route as userAddressHistorySearchRoute } from './pages/userAddressHistorySearch';
import { route as userIntegrationStagingSearchRoute } from './pages/userIntegrationStagingSearch';
import { route as userJobInfoHistorySearchRoute } from './pages/userJobInfoHistorySearch';
import { route as userMainDataSearchRoute } from './pages/userMainDataSearch';
import { route as userPersonalInfoHistorySearchRoute } from './pages/userPersonalInfoHistorySearch';
//Generator1

const routes: RouteItem[] = [
inboundSearchRoute,
inboundAltairRoleStackSearchRoute,
altairRoleDefinitionSearchRoute,
inboundAltairRoleSearchRoute,
userAltairRoleSearchRoute,
userFinancialDetailSearchRoute,
userBusinessUnitsAccessSearchRoute,
outboundSearchRoute,
altairOrgStructureSearchRoute,
altairAgencyRuleSearchRoute,
altairBusinessUnitSearchRoute,
altairUserSearchRoute,
emailHistorySearchRoute,
emailSettingSearchRoute,
emailTemplateSearchRoute,
wipCorrectionSearchRoute,
altairJobv2SearchRoute,
pXPProjectCreationSearchRoute,
inboundUserStackSearchRoute,
roarUserSearchRoute,
userAddressHistorySearchRoute,
userIntegrationStagingSearchRoute,
userJobInfoHistorySearchRoute,
userMainDataSearchRoute,
userPersonalInfoHistorySearchRoute,
//Generator2
];

export default routes;