// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/AltairAlternateCalendarSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'AltairAlternateCalendar',
  menu: 'StoreOldApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: false,
  delete: false,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hFMCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'agencyName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'effectiveFrom',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'effectiveTo',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'weeklyWorkingHours',
      validators: [Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'mon',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'tue',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'wed',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'thu',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'fri',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'sat',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'sun',
      validators: [Validators.required, Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isCreatedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isUpdatedInClarizen',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedOn',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdOn',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedBy',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdBy',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'hFMCode'
    }),
    new PageField({
      name: 'agencyCode'
    }),
    new PageField({
      name: 'agencyName'
    }),
    new PageField({
      name: 'effectiveFrom4Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveFrom5LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveTo6Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'effectiveTo7LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'weeklyWorkingHours',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'mon',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'tue',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'wed',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'thu',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'fri',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'sat',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'sun',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'isCreatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isUpdatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'updatedOn18Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedOn19LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdOn20Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdOn21LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedBy'
    }),
    new PageField({
      name: 'createdBy'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'hFMCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyCode',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'agencyName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'effectiveFrom',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'effectiveTo',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'weeklyWorkingHours',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'mon',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'tue',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'wed',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'thu',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'fri',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'sat',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'sun',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'isCreatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isUpdatedInClarizen',
      enumName: 'YesNo',
      enum: StoreOldApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedOn',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdOn',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedBy',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdBy',
      dataType: 'string'
    })
  ],
});

