// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import StoreOldApiEnums from '../../storeOldApiEnums';

export const pageConfig = new PageConfig({
  route: '/StoreOldApi/ReconcileMultiOrgDataSearch',
  moduleKey: 'StoreOldApi',
  pageKey: 'ReconcileMultiOrgData',
  menu: 'migration',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: true,
  delete: true,
  export: true,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'summaryId',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'entityType',
      validators: [Validators.required, Validators.max(2147483647), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'entityId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'data',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hash',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdAt',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'updatedAt',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'id',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'summaryId',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'entityType',
      filterComponent: CC.NumberComponent
    }),
    new PageField({
      name: 'entityId'
    }),
    new PageField({
      name: 'data',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'hash',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'createdAt6Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdAt7LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedAt8Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'updatedAt9LessEq',
      filterComponent: CC.DateComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'summaryId',
      isSortable: true,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'entityType',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'entityId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'hash',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdAt',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'updatedAt',
      dataType: 'string'
    })
  ],
});

